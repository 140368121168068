import type { ReactNode, FC } from 'react';
import {
  SwanProvider,
  SwanSSRProvider,
  useBrowserClasses,
  SWAN_BASE_URL_MAP,
} from '@vp/swan';

export const SwanConfiguration: FC<any> = ({
  children,
}: {
  children: ReactNode;
}) => {
  useBrowserClasses(true); // Passing true, auto adds browser class to head, using Vanilla JS QuerySelector

  return (
    <SwanProvider swanBaseUrl={SWAN_BASE_URL_MAP.default.cdn}>
      <SwanSSRProvider>
        {/* Optionally, if you need, wrap ScreenProvider at this level */}
        {children}
      </SwanSSRProvider>
    </SwanProvider>
  );
};
