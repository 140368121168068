import type { GatsbyBrowser } from 'gatsby';
import { HashRouter } from 'react-router-dom';
import { SwanConfiguration } from './src/components/shared/SwanConfiguration';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
  props,
}: any) => (
  <HashRouter hashType="noslash">
    <SwanConfiguration {...props}>{element}</SwanConfiguration>
  </HashRouter>
);
